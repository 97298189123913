<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/schedule-top-img.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="3"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="3"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 内容开始 -->
    <div class="middle">
      <div class="container">
        <!-- 面包屑开始 -->
        <div class="breadcrumb">
          <div class="breadcrumb-label">
            <div v-show="language == 'cn'">当前位置：</div>
            <div v-show="language == 'en'">Current location:</div>
          </div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">{{
              $t("message.topNav1")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/schedule' }">
              {{ $t("message.topNav4") }}</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{
              $t("message.scheduleDetail")
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 面包屑结束 -->
        <!-- 标题开始 -->
        <div class="middle-title">
          <div v-show="language == 'cn'">{{ msg.name }}</div>
          <div v-show="language == 'en'">{{ msg.enName }}</div>
        </div>
        <!-- 标题结束 -->
        <!-- 其他开始 -->
        <div class="middle-msg hidden-xs-only">
          <!-- 展会时间开始 -->
          <div class="middle-time">
            {{ $t("message.scheduleTime") }}：
            {{ msg.startTime | filterTime }}至
            {{ msg.endTime | filterTime }}
          </div>
          <!-- 展会时间结束 -->
          <!-- 展会地址开始 -->
          <div class="middle-address">
            {{ $t("message.scheduleAddress") }}：
            <div v-show="language == 'cn'">{{ msg.expo }}</div>
            <div v-show="language == 'en'">{{ msg.enExpo }}</div>
          </div>
          <!-- 展会地址结束 -->
        </div>
        <div class="middle-msg-sm hidden-sm-and-up">
          <!-- 展会时间开始 -->
          <div class="middle-time-sm">
            <span></span>{{ $t("message.scheduleTime") }}：{{
              msg.startTime | filterTime
            }}~
            {{ msg.endTime | filterTime }}
          </div>
          <!-- 展会时间结束 -->
          <!-- 展会地址开始 -->
          <div class="middle-address-sm">
            <span></span>{{ $t("message.scheduleAddress") }}：
            <label v-show="language == 'cn'">{{ msg.expo }}</label>
            <label v-show="language == 'en'">{{ msg.enExpo }}</label>
          </div>
          <!-- 展会地址结束 -->
        </div>
        <!-- 其他结束 -->
        <!-- 详情开始 -->
        <div class="middle-detail">
          <!-- 标题开始 -->
          <div class="middle-detail-title">
            {{ $t("message.exhibitionDetails") }}：
          </div>
          <!-- 标题结束 -->
          <!-- 内容开始 -->
          <div class="content-box">
            <div v-show="language == 'cn'">
              <div class="middle-detail-msg" v-html="msg.content"></div>
            </div>
            <div v-show="language == 'en'">
              <div class="middle-detail-msg" v-html="msg.enContent"></div>
            </div>
          </div>

          <!-- 内容结束 -->
        </div>
        <!-- 详情结束 -->
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      msg: {},
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.$axios
      .get(`api/getExpo?id=${id}`, {})
      .then((res) => {
        if (res.data.code == 0) {
          this.msg = res.data.data;
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  methods: {},
  filters: {
    filterTime: function (value) {
      let date = new Date(value);
      let month = date.getMonth() + 1; //当前月份
      let day = date.getDate();
      return `${month}/${day}`;
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.breadcrumb {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.middle-title {
  color: #333333;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
  margin-top: 40px;
}

.middle-msg {
  color: #004bc7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.middle-time {
  margin-right: 60px;
}

.middle-address {
  display: flex;
}

.middle-detail {
  background-color: #f5f5f5;
  margin: 20px 0;
  padding: 20px;
}

.middle-detail-title {
  color: #666666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 20px;
}

.middle-detail-msg {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
}

.middle-otherMsg {
  color: #666666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 80px;
}

.middle-otherMsg-txt1 {
  margin-bottom: 20px;
}

.middle-otherMsg-txt2 {
  margin-bottom: 30px;
}

.middle-otherMsg-img img {
  width: 100%;
}

@media (min-width: 768px) {
  .middle-detail {
    background-color: #f5f5f5;
    margin: 40px 0;
    padding: 40px;
  }

  .middle-detail-title {
    color: #666666;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .middle-detail-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
  }

  .middle-otherMsg-txt2 {
    margin-bottom: 60px;
  }
}

.middle-title-sm {
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
  margin-top: 20px;
}

.middle-msg-sm {
  color: #004bc7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 19px;
  margin: 20px 0;
}

.middle-msg-sm span {
  width: 6px;
  height: 6px;
  border: 1px solid #004bc7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.middle-time-sm {
  margin-bottom: 16px;
}

.middle-address-sm {
  color: #004bc7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 19px;
  margin: 20px 0;
}

.middle-address-sm span {
  width: 6px;
  height: 6px;
  border: 1px solid #004bc7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.content-box >>> img {
  width: 100%;
}
</style>
